import { atomWithStore } from 'jotai-zustand';
import { createStore, StoreApi } from 'zustand/vanilla';
import { getCookie } from '../cookiesControls';

export enum Theme {
  DARK = 'dark',
  LIGHT = 'light',
}

const DARK_COLOR_SCHEME_QUERY = '(prefers-color-scheme: dark)';

const cookieTheme = getCookie('theme');
// const matchesDarkTheme = typeof window !== 'undefined' && window.matchMedia(DARK_COLOR_SCHEME_QUERY).matches;
export const themeStore: StoreApi<Theme> = createStore(() => (cookieTheme as Theme || Theme.DARK));
if (typeof document !== 'undefined') {
  document.documentElement.dataset.theme = themeStore.getState();
  themeStore.subscribe(() => {
    document.documentElement.dataset.theme = themeStore.getState();
  });
}

export const theme = atomWithStore(themeStore);

const checkTheme = (event: MediaQueryListEvent) => {
  if (!cookieTheme && typeof document !== 'undefined') {
    const newColorScheme = event.matches ? Theme.DARK : Theme.LIGHT;
    themeStore.setState(newColorScheme);
    document.documentElement.dataset.theme = newColorScheme;
  }
};

typeof window !== 'undefined' && window.matchMedia(DARK_COLOR_SCHEME_QUERY).addEventListener('change', checkTheme);
